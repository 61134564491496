import React, { useState } from 'react';
import BottomTabBar from '../../navigation/BottomTabBar';
import Navbar from '../../navigation/Navbar';
import { NavigationTab } from '../../navigation/NavigationTab';
import { Link } from 'react-router-dom';

const ApologeticsCourse = () => {
  return (
    <div className='card'>
      <div className='card-body'>
        <h4>The Story of Reality: Basic Evidence for the Catholic Faith</h4>

        <p className='h6'>7:00 PM - 8:00 PM CT • Zoom • 273 attending</p>
        <p className='h6 mb-2'>
          Dr. Biology Person, Dr. History Person, Dr. Theology Person
        </p>

        <div className='d-flex flex-column flex-sm-row'>
          <div className='d-flex flex-row align-items-center mt-2 me-3'>
            <button className='btn btn-dark me-2'>Attending</button>
            <button className='btn btn-outline-dark'>Not Attending</button>
          </div>

          <button className='btn btn-primary mt-2'>Add to Calendar</button>
        </div>
      </div>
    </div>
  );
};

const MinistryOpportunities = () => {
  const [expanded, setExpanded] = useState(false);
  const toggleExpanded = () => setExpanded((st) => !st);
  return (
    <div>
      <div
        className='d-flex flex-row align-items-center mt-4 mb-3'
        onClick={toggleExpanded}
      >
        <span
          className='material-symbols-rounded me-2'
          style={{ fontSize: '1.75rem', cursor: 'pointer' }}
        >
          {expanded ? 'expand_more' : 'chevron_right'}
        </span>
        <h3 className='mb-0'>Ministry Opportunities</h3>
      </div>

      {expanded ? (
        <div className='row'>
          <div className='col-12 col-md-3 mb-3'>
            <div>
              <iframe
                src='https://www.youtube.com/embed/z-pw8kRZhnU?si=DZn6iyKinE3gWfPU'
                title='YouTube video player'
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                className='rounded-3 w-100'
                style={{ aspectRatio: 16 / 9 }}
                allowFullScreen={true}
              ></iframe>

              <h4 className='card-title'>Spiritus Ministries</h4>
              <h6 className='mb-0'>Menasha, WI</h6>
            </div>
          </div>
          <div className='col-12 col-md-3 mb-3'>
            <div>
              <iframe
                src='https://www.youtube.com/embed/0nqTTFVUMFc?si=W8NNKBrwPP_-cZy1'
                title='YouTube video player'
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                className='rounded-3 w-100'
                style={{ aspectRatio: 16 / 9 }}
                allowFullScreen={true}
              ></iframe>

              <h4 className='card-title'>NET Ministries</h4>
              <h6 className='mb-0'>St Paul, MN</h6>
            </div>
          </div>
          <div className='col-12 col-md-3 mb-3'>
            <div>
              <iframe
                src='https://www.youtube.com/embed/1r4yrbkuJgw?si=iY0-QrKZRgG2HQqt'
                title='YouTube video player'
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                className='rounded-3 w-100'
                style={{ aspectRatio: 16 / 9 }}
                allowFullScreen={true}
              ></iframe>

              <h4 className='card-title'>The Pines Catholic Camp</h4>
              <h6 className='mb-0'>Big Sandy, TX</h6>
            </div>
          </div>
          <div className='col-12 col-md-3'></div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

const ServiceOpportunities = () => {
  const [expanded, setExpanded] = useState(false);
  const toggleExpanded = () => setExpanded((st) => !st);
  return (
    <div>
      <div
        className='d-flex flex-row align-items-center mt-4 mb-3'
        onClick={toggleExpanded}
      >
        <span
          className='material-symbols-rounded me-2'
          style={{ fontSize: '1.75rem', cursor: 'pointer' }}
        >
          {expanded ? 'expand_more' : 'chevron_right'}
        </span>
        <h3 className='mb-0'>Service Opportunities</h3>
      </div>
    </div>
  );
};

const Events = () => {
  const [expanded, setExpanded] = useState(false);
  const toggleExpanded = () => setExpanded((st) => !st);
  return (
    <div>
      <div
        className='d-flex flex-row align-items-center mt-4 mb-3'
        onClick={toggleExpanded}
      >
        <span
          className='material-symbols-rounded me-2'
          style={{ fontSize: '1.75rem', cursor: 'pointer' }}
        >
          {expanded ? 'expand_more' : 'chevron_right'}
        </span>
        <h3 className='mb-0'>Events</h3>
      </div>
      <div className='row'></div>
    </div>
  );
};

const Learn = () => {
  const [expanded, setExpanded] = useState(true);
  const toggleExpanded = () => setExpanded((st) => !st);

  return (
    <div>
      <div
        className='d-flex flex-row justify-content-between align-items-center mt-4 mb-3'
        onClick={toggleExpanded}
        style={{ cursor: 'pointer' }}
      >
        <div className='d-flex flex-row align-items-center'>
          <span
            className='material-symbols-rounded me-2'
            style={{ fontSize: '1.75rem' }}
          >
            {expanded ? 'expand_more' : 'chevron_right'}
          </span>
          <h2 className='mb-0'>Learn</h2>
        </div>
        <Link className="btn btn-dark" to='/learn'>See More</Link>
      </div>

      {expanded && (
        <div className='row'>
          <div className='col-12 col-md-4 col-xl-3 mb-3'>
            <LearnCard
              imgsrc={
                'https://saltandlighttv.org/blog/wp-content/uploads/2018/10/20140707cm00702-e1538598922345.jpg'
              }
              title='The Mass'
              totalEpisodes={4}
              episodes={['What is the Mass?', 'The Biblical Roots of the Mass']}
            />
          </div>
          <div className='col-12 col-md-4 col-xl-3 mb-3'>
            {/* https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSiXLC8Lm2UlnEKuZ7T_j70BM7LcJseW3q_UQ&usqp=CAU */}
            <LearnCard
              imgsrc={
                'https://www.allsaintswichita.com/uploads/1/1/2/6/112655677/confirmdove_1_orig.jpg'
              }
              title='The 7 Sacraments'
              totalEpisodes={10}
              episodes={[
                'What is a Sacrament?',
                'The Biblical Roots of the Sacraments',
              ]}
            />
          </div>
          <div className='col-12 col-md-4 col-xl-3 mb-3'>
            <LearnCard
              imgsrc={
                'https://popehistory.com/wp-content/uploads/2017/01/pope-st-peter.jpg'
              }
              title='The Office of the Pope'
              totalEpisodes={6}
              episodes={[
                'Why did Jesus establish the papacy?',
                'When can the Pope speak infallibly?',
              ]}
            />
          </div>
          <div className='col-12 col-md-3 mb-3'>
            <LearnCard
              imgsrc={
                'https://assets.editorial.aetnd.com/uploads/2018/01/discovery-shows-early-christians-didnt-always-take-the-bible-literallys-featured-photo.jpg'
              }
              title='Scripture & Tradition'
              totalEpisodes={6}
              episodes={[
                'Why Christianity is a Tradition',
                'What is Sacred Tradition?',
              ]}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export const LearnCard = ({
  imgsrc,
  title,
  totalEpisodes,
  episodes,
}: {
  imgsrc: string;
  title: string;
  totalEpisodes: number;
  episodes: string[];
}) => {
  return (
    <div className='card border-0'>
      <img
        className='card-img-top rounded-3 shadow-sm'
        src={imgsrc}
        alt='The Holy Mass Consecratation'
        style={{ height: '200px', objectFit: 'cover' }}
      />
      <div className='px-2 pt-2'>
        <div className='d-flex flex-row align-items-center justify-content-between mb-sm'>
          <h5 className='card-title mb-0'>{title}</h5>
          <p className='mb-0 d-flex flex-row align-items-center'>
            <span>{totalEpisodes}</span>
            <span className='material-symbols-rounded me-2'>slideshow</span>
            <span className='material-symbols-rounded'>more_horiz</span>
          </p>
        </div>
      </div>
      <div className='d-flex flex-column p-sm'>
        {episodes.map((ep) => {
          return (
            <p className='mb-sm d-flex flex-row align-items-center'>
              <span className='material-symbols-rounded'>play_arrow</span>
              <span>{ep}</span>
            </p>
          );
        })}
        <button className='mt-2 btn btn-light border rounded p-sm mb-0'>
          See More
        </button>
      </div>
    </div>
  );
};

const HomePage = () => {
  return (
    <div>
      <Navbar tab={NavigationTab.home} />

      <div className='container'>
        <ApologeticsCourse />
      </div>

      <div className='container mt-2'>
        <Learn />
      </div>

      <div className='container mt-2'>
        <MinistryOpportunities />

        <ServiceOpportunities />

        <Events />

        <div className='d-md-none my-5 py-5'></div>
      </div>
      <BottomTabBar tab={NavigationTab.home} />
    </div>
  );
};

export default HomePage;
