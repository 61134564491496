import React from 'react';
import BottomTabBar from '../../navigation/BottomTabBar';
import Navbar from '../../navigation/Navbar';
import { NavigationTab } from '../../navigation/NavigationTab';

const AccountPage = () => {
  return (
    <div>
      <Navbar tab={NavigationTab.account}/>
      <div className='container'>
        <h1>Account</h1>
      </div>
      <BottomTabBar tab={NavigationTab.account} />
    </div>
  );
};

export default AccountPage;
