import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { NavigationTab } from './NavigationTab';

const getTabText = (tab: NavigationTab) => {
  if (tab === NavigationTab.home) return 'Home';
  else if (tab === NavigationTab.learn) return 'Learn';
  else if (tab === NavigationTab.story) return 'Story';
  else if (tab === NavigationTab.docs) return 'Docs';
  else if (tab === NavigationTab.maps) return 'Maps';
  else if (tab === NavigationTab.library) return 'Library';
};

const Navbar = ({
  tab,
  borderBottom,
}: {
  tab: NavigationTab;
  borderBottom?: boolean;
}) => {
  const { pathname } = useLocation();

  return (
    <nav
      className={`navbar navbar-expand-sm bg-white ${
        borderBottom || false ? 'border-bottom' : ''
      }`}
      style={{ padding: '0.75rem' }}
    >
      <div className='container-fluid d-flex flex-row align-items-center justify-content-between'>
        <div className='d-flex flex-row align-items-center flex-fill'>
          <div className='d-flex flex-row align-items-center'>
            <img
              src='/logo.png'
              alt='Logo'
              height='50'
              width='50'
              className='me-2'
            />
            <Link className='navbar-brand text-decoration-none' to={'/'}>
              Catholicam{' '}
              <span className='lead d-md-none'>{getTabText(tab)}</span>
            </Link>
          </div>
          <div className='d-none d-md-block'>
            <ul className='navbar-nav align-items-stretch'>
              <li className='nav-item me-2'>
                <Link
                  className={`nav-link d-flex flex-row align-items-center ${
                    tab === NavigationTab.home
                      ? 'bg-dark rounded text-white'
                      : ''
                  }`}
                  to='/home'
                >
                  <span className='material-symbols-rounded'>home</span>
                  <span className='ms-2 d-md-none d-lg-inline'>Home</span>
                </Link>
              </li>
              <li className='nav-item me-2'>
                <Link
                  className={`nav-link d-flex flex-row align-items-center ${
                    tab === NavigationTab.learn
                      ? 'bg-dark rounded text-white'
                      : ''
                  }`}
                  to='/learn'
                >
                  <span className='material-symbols-rounded'>donut_large</span>
                  <span className='ms-2 d-md-none d-lg-inline'>Learn</span>
                </Link>
              </li>

              <li className='nav-item me-2'>
                <Link
                  className={`nav-link d-flex flex-row align-items-center ${
                    tab === NavigationTab.story
                      ? 'bg-dark rounded text-white'
                      : ''
                  }`}
                  to='/story'
                >
                  <span className='material-symbols-rounded'>timeline</span>
                  <span className='ms-2 d-md-none d-lg-inline'>Story</span>
                </Link>
              </li>

              <li className='nav-item me-2'>
                <Link
                  className={`nav-link d-flex flex-row align-items-center ${
                    tab === NavigationTab.docs
                      ? 'bg-dark rounded text-white'
                      : ''
                  }`}
                  to='/docs'
                >
                  <span className='material-symbols-rounded'>
                    library_books
                  </span>
                  <span className='ms-2 d-md-none d-lg-inline'>Docs</span>
                </Link>
              </li>

              <li className='nav-item me-2'>
                <Link
                  className={`nav-link d-flex flex-row align-items-center ${
                    tab === NavigationTab.maps
                      ? 'bg-dark rounded text-white'
                      : ''
                  }`}
                  to='/maps'
                >
                  <span className='material-symbols-rounded'>map</span>
                  <span className='ms-2 d-md-none d-lg-inline'>Maps</span>
                </Link>
              </li>

              <li className='nav-item me-2'>
                <Link
                  className={`nav-link d-flex flex-row align-items-center ${
                    tab === NavigationTab.library
                      ? 'bg-dark rounded text-white'
                      : ''
                  }`}
                  to='/library'
                >
                  <span className='material-symbols-rounded'>
                    collections_bookmark
                  </span>
                  <span className='ms-2 d-md-none d-lg-inline'>Library</span>
                </Link>
              </li>

              <li className='nav-item me-2'>
                <Link
                  className={`nav-link d-flex flex-row align-items-center ${
                    tab === NavigationTab.search
                      ? 'bg-dark rounded text-white'
                      : ''
                  }`}
                  to='/search'
                >
                  <span className='material-symbols-rounded'>search</span>
                  <span className='ms-2 d-md-none d-lg-inline'>Search</span>
                </Link>
              </li>
            </ul>
          </div>
        </div>

        <div className='d-flex flex-row align-items-center'>
          <Link
            to='/search'
            className={`d-flex flex-row align-items-center text-decoration-none d-md-none rounded-3 me-3 ${
              tab === NavigationTab.search
                ? 'bg-dark rounded text-white'
                : 'text-dark'
            }`}
            style={{ padding: '0.35rem' }}
          >
            <span
              className='material-symbols-rounded'
              style={{ fontSize: '2rem' }}
            >
              search
            </span>
          </Link>

          <Link
            to='/account'
            className='d-flex flex-row align-items-center text-decoration-none text-dark'
          >
            <span
              className='material-symbols-rounded me-3'
              style={{ fontSize: '2rem' }}
            >
              account_circle
            </span>
          </Link>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
