import docs, { DocsMap, DocsTopic } from './docs';

export const STORY_PERIODS: STORY_PERIODS_TYPE[] = [
  'creation',
  'fall',
  'israel',
  'jesus',
  'church',
  'end',
];

export type STORY_PERIODS_TYPE =
  | 'creation'
  | 'fall'
  | 'israel'
  | 'jesus'
  | 'church'
  | 'end';

export interface StoryPeriod {
  title: string;
  slug: string;
  time: string | null;
  docs: { address: string[] }[] | null;
  children: StoryPeriod[];
}

export interface StoryMap {
  creation: StoryPeriod;
  fall: StoryPeriod;
  israel: StoryPeriod;
  jesus: StoryPeriod;
  church: StoryPeriod;
  end: StoryPeriod;
}

const story: StoryMap = {
  creation: {
    title: 'Creation',
    time: '13.8 billion years ago - Ongoing',
    slug: 'creation',
    docs: [],
    children: [
      {
        title: 'The Big Bang',
        time: '13.8 billion years ago',
        slug: 'creation/big-bang',
        children: [],
        docs: [],
      },
      {
        title: 'Formation of Solar System',
        time: '4.55 billion years ago',
        slug: 'creation/solar-system',
        children: [],
        docs: [],
      },
      {
        title: 'Formation of Earth',
        time: '4.54 billion years ago',
        slug: 'creation/formation-earth',
        children: [],
        docs: [],
      },
      {
        title: 'First Life on Earth',
        time: '3.42 billion years ago',
        slug: 'creation/first-life',
        children: [],
        docs: [],
      },
      {
        title: 'Cambrian Explosion',
        time: '541 - 530 million years ago',
        slug: 'creation/cambrian-explosion',
        children: [],
        docs: [],
      },
      {
        title: 'First Humans',
        time: '80,000 - 70,000 years ago',
        slug: 'creation/first-humans',
        children: [],
        docs: [],
      },
    ],
  },
  fall: {
    title: 'The Fall',
    slug: 'fall',
    time: '70,000 - 65,000 years ago',
    children: [],
    docs: [],
  },
  israel: {
    title: 'The Formation of Israel',
    time: '5,000 years ago - 1 BC',
    slug: 'israel',
    children: [],
    docs: [],
  },
  jesus: {
    title: 'Jesus',
    time: '1-33 AD',
    slug: 'jesus',
    children: [
      {
        title: 'The Last Supper',
        time: '33 AD',
        slug: 'jesus/last-supper',
        docs: [],
        children: [
          {
            title: 'Institution of the Eucharist',
            time: '33 AD',
            slug: 'jesus/institution-eucharist',
            docs: [],
            children: [],
          },
        ],
      },
      {
        title: "Jesus's Sacrifice at Calvary",
        time: '33 AD',
        slug: 'jesus/sacrifice-calvary',
        docs: [],
        children: [],
      },
    ],
    docs: [],
  },
  church: {
    title: 'The Catholic Church',
    time: '33 AD - today',
    slug: 'church',
    children: [
      {
        title: 'Founding of the Catholic Church',
        time: '33 AD',
        slug: 'church/founding',
        docs: [],
        children: [],
      },
      {
        title: 'Conversion of Constantine',
        time: '313 AD',
        slug: 'church/conversion-constantine',
        docs: [],
        children: [],
      },
    ],
    docs: [],
  },
  end: {
    title: 'The Final Judgement',
    time: 'End of Time',
    slug: 'end',
    children: [],
    docs: [],
  },
};

export default story;
