import React from 'react';
import BottomTabBar from '../../navigation/BottomTabBar';
import Navbar from '../../navigation/Navbar';
import { NavigationTab } from '../../navigation/NavigationTab';

const SearchPage = () => {
  return (
    <div>
      <Navbar tab={NavigationTab.search} />
      <div className='container d-flex flex-column align-items-center' style={{marginTop: '0.35rem'}}>
        <input
          type='text'
          className='form-control'
          placeholder='Search'
          style={{ maxWidth: '800px' }}
        />
      </div>
      <div
        className='d-flex flex-row justify-content-start mt-2 w-100 hide-scrollbar'
        style={{ overflow: 'scroll' }}
      >
        <div className='d-flex flex-row justify-content-center flex-fill px-2'>
          <p className='py-2 px-3 border rounded-3 mb-0 me-2'>All</p>
          <p className='py-2 px-3 border rounded-3 mb-0 me-2'>Saints</p>
          <p className='py-2 px-3 border rounded-3 mb-0 me-2'>Clergy</p>
          <p className='py-2 px-3 border rounded-3 mb-0 me-2'>Dioceses</p>
          <p className='py-2 px-3 border rounded-3 mb-0 me-2'>Docs</p>
          <p className='py-2 px-3 border rounded-3 mb-0 me-2'>Places</p>
          <p className='py-2 px-3 border rounded-3 mb-0 me-2'>Miracles</p>
          <p className='py-2 px-3 border rounded-3 mb-0 me-2'>Institutions</p>
          <p className='py-2 px-3 border rounded-3 mb-0 me-2'>Events</p>
        </div>
      </div>

      <div className='container mt-4'>
        <div className='row'>
          <div className='col-12 col-md-4 mb-2'>
            <div className='card card-body'></div>
          </div>
          <div className='col-12 col-md-4 mb-2'>
            <div className='card card-body'></div>
          </div>
          <div className='col-12 col-md-4 mb-2'>
            <div className='card card-body'></div>
          </div>
        </div>
      </div>
      <BottomTabBar tab={NavigationTab.search} />
    </div>
  );
};

export default SearchPage;
