import React from 'react';
import BottomTabBar from '../../navigation/BottomTabBar';
import Navbar from '../../navigation/Navbar';
import { NavigationTab } from '../../navigation/NavigationTab';

const LearnRow = () => {
  return (
    <div
      className='container mb-3 hide-scrollbar'
      style={{ overflow: 'scroll' }}
    >
      <div className='d-flex flex-row'>
        {['Series 1', 'Series 2', 'Series 3'].map((s) => {
          return (
            <div>
              <div className='card px-5 py-4 bg-light me-2'>
                <p className='mb-0'>{s}</p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const LearnPage = () => {
  return (
    <div>
      <Navbar tab={NavigationTab.learn} />

      <div className='container mb-4'>
        <div className='d-flex flex-column flex-sm-row align-items-stretch align-items-sm-center'>
          <p className='mb-0 me-3 h5 fw-bold'>39 / 52 items • 75%</p>
          <div className='d-sm-none mb-2'></div>
          <div className='progress shadow-sm flex-grow-1' role='progressbar'>
            <div className='progress-bar w-75'></div>
          </div>
        </div>
      </div>

      <div className='container'>
        <h2>
          The Bride of Christ: The Origin and Structure of the Catholic Church
        </h2>
      </div>
      <LearnRow />

      <div className='container'>
        <h2>The 7 Sacraments: The Divinely Instituted Means of Salvation</h2>
      </div>
      <LearnRow />

      <div className='container'>
        <h2>The Glory and Dignity of the Human Person: Catholic Teaching</h2>
      </div>
      <LearnRow />

      <div className='container'>
        <h2>Angels, Demons, and Miracles: The Supernatural in Human History</h2>
      </div>
      <LearnRow />

      <div className='container'>
        <h2>Transforming the World: The Epic Christian Missionary Adventure</h2>
      </div>
      <LearnRow />

      <div className="my-5 py-2 d-md-none"></div>

      <BottomTabBar tab={NavigationTab.learn} />
    </div>
  );
};

export default LearnPage;
