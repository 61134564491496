import React from 'react';
import BottomTabBar from '../../navigation/BottomTabBar';
import Navbar from '../../navigation/Navbar';
import { NavigationTab } from '../../navigation/NavigationTab';

const LibraryPage = () => {
  return (
    <div>
      <Navbar tab={NavigationTab.library} />

      <div className='container'>
        <div className='d-flex flex-row justify-content-between align-items-center mb-2'>
          <p className='h2 mb-0'>Media</p>
          <button className='btn btn-link text-dark text-decoration-none fw-bold d-flex flex-row align-items-center'>
            See More
            <span className='material-symbols-rounded'>chevron_right</span>
          </button>
        </div>
      </div>

      <div
        className='container d-flex flex-row hide-scrollbar mb-4'
        style={{ height: '75px', overflow: 'scroll' }}
      >
        <img
          src='https://yt3.googleusercontent.com/3jw_ygGVm8LHtRLWBvhQspGpkY_Cmu29XPsUZ4oo0GeeR8FTAQ9HYHd1IZKw1dqhLDUBJXkhJJo=s900-c-k-c0x00ffffff-no-rj'
          alt=''
          className='rounded-3 me-2'
        />
        <img
          src='https://bulldogcatholic.org/wp-content/uploads/2022/09/father-mike-schmitz-umd-bulldog-catholic-photo-landscape-2022-updated.jpg'
          alt=''
          className='rounded-3 me-2'
        />
        <img
          src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRdJLod6PxZcafhAw9kULHme6y28CfPqovekxdWGK3hPOQjdJij'
          alt=''
          className='rounded-3 me-2'
        />
        <img
          src='https://breakinginthehabit.files.wordpress.com/2021/10/yp637gtz-29188_original.jpg'
          alt=''
          className='rounded-3 me-2'
        />
        <img
          src='https://selectinternationaltours.com/wp-content/uploads/2020/08/Fr.-Chris-Alar-1.jpg'
          alt=''
          className='rounded-3 me-2'
        />
        <img
          src='https://steubenvilleconferences.com/wp-content/uploads/2020/10/ChrisStefanick_2020-e1603812350453.jpg'
          alt=''
          className='rounded-3 me-2'
        />
        <img
          src='https://yt3.googleusercontent.com/qbdvVSFhqFHLGZzd7hqXDU7V9l1xMnlrpXlGkS7YabVKQYZ1In1ilfGGEo5yUn8t0QU_Zg8M=s900-c-k-c0x00ffffff-no-rj'
          alt=''
          className='rounded-3 me-2'
        />
        <img
          src='https://yt3.googleusercontent.com/oDuCiQKh5Se1vJjarwDWYFMPVurbpvFbxi43zmZrurtvJT8KZtA8AdqKTn3XGFW8NOMgX2uzEw=s176-c-k-c0x00ffffff-no-rj'
          alt=''
          className='rounded-3 me-2'
        />
        <img
          src='https://yt3.googleusercontent.com/OPda8YG9op9_6tqPKd-r0I2a5SCChka7H-rCe5c0q1Y1_llrIOzwq21DHHvs_nY9qyXwJdx0oA=s900-c-k-c0x00ffffff-no-rj'
          alt=''
          className='rounded-3 me-2'
        />
        <img
          src='https://yt3.googleusercontent.com/ytc/APkrFKbGlE2bvmbWCZ_O0rh_Aq3wFEHuTwmnlHdIEPwaVA=s176-c-k-c0x00ffffff-no-rj'
          alt=''
          className='rounded-3 me-2'
        />
        <img
          src='https://yt3.googleusercontent.com/ytc/APkrFKaetNo-lHSfHh0ED9YPvUaORJiMNvT_8HtdRMFY=s176-c-k-c0x00ffffff-no-rj'
          alt=''
          className='rounded-3 me-2'
        />
        <img
          src='https://yt3.googleusercontent.com/dIpSRcv6AqpcjcIE1E0t_E6SHYX4JyyaAzFpYHCspmIRTZ6FlMFByOAdF4_M9CQmhmHRqUpi=s176-c-k-c0x00ffffff-no-rj'
          alt=''
          className='rounded-3 me-2'
        />
        <img
          src='https://yt3.googleusercontent.com/qwRQ6Kb_sLDHJQFQziRyvppY5Ck4eJAcbAzW-3J0YhBwzCxrhgy65TlITHN3FDds56F3-XAn=s176-c-k-c0x00ffffff-no-rj'
          alt=''
          className='rounded-3 me-2'
        />
        <img
          src='https://yt3.googleusercontent.com/4HWZRdnhfjknw4qsw2xbNDRmwTXiq--yYmWGXALBc3dPJQ5Urk_IGAW9QU-Qtb6eaYrtMWvNcg=s176-c-k-c0x00ffffff-no-rj'
          alt=''
          className='rounded-3 me-2'
        />
        <img
          src='https://yt3.googleusercontent.com/CgGW6tNXuM7BWSZXjvQBMdKKFWhgCX_jmfJOGRisnG-TahHgWcFrbncqsWWLxRZxJmx55BC7=s900-c-k-c0x00ffffff-no-rj'
          alt=''
          className='rounded-3 me-2'
        />
        <img
          src='https://yt3.googleusercontent.com/ytc/APkrFKa9OFLFWaEfo8G4yYkzm1RtAqfgQN1Qv_WDE1QIBA=s176-c-k-c0x00ffffff-no-rj'
          alt=''
          className='rounded-3 me-2'
        />
        <img
          src='https://yt3.googleusercontent.com/ytc/APkrFKYFJKynR4Y6YllxBNagiur8jIWtpxozqSSH-hI=s176-c-k-c0x00ffffff-no-rj'
          alt=''
          className='rounded-3 me-2'
        />
        <img
          src='https://yt3.googleusercontent.com/II6dLE4zX6AyPCjU3jM3x3vdsvl_uv7b1u4ZLfaKXNlMUNtkHm3JvPNiiVXSeFcwC95WnQvcNw=s176-c-k-c0x00ffffff-no-rj'
          alt=''
          className='rounded-3 me-2'
        />
        <img
          src='https://yt3.googleusercontent.com/ytc/APkrFKauYwDXUWygzGhtgzWAoIyXgzd6dZ6HBr35kOo26g=s176-c-k-c0x00ffffff-no-rj'
          alt=''
          className='rounded-3 me-2'
        />
      </div>

      <div className='container'>
        <div className='d-flex flex-row justify-content-between align-items-center mb-2'>
          <p className='h2 mb-0'>Titles for you</p>
          <button className='btn btn-link text-dark text-decoration-none fw-bold d-flex flex-row align-items-center'>
            See More
            <span className='material-symbols-rounded'>chevron_right</span>
          </button>
        </div>
        <div className='mb-3'>
          <p className='h5'>Catholic Essentials</p>
          <div className='row'>
            <div className='col-6 col-sm-4 col-md-3 col-xl-2'>
              <div className='card border-0 mb-2'>
                <img
                  src='https://m.media-amazon.com/images/I/91o6I2t5BwL._SY522_.jpg'
                  alt=''
                  className='card-img-top rounded-3'
                />
                <div className='card-body px-2 pt-2 pb-0'>
                  <p className='h6 mb-0'>
                    CATHOLICISM: A Journey to the Heart of the Faith
                  </p>
                  <p className='text-muted mb-0'>Bishop Robert Barron</p>
                </div>
              </div>
            </div>
            <div className='col-6 col-sm-4 col-md-3 col-xl-2'>
              <div className='card border-0 mb-2'>
                <img
                  src='https://m.media-amazon.com/images/I/71mY+lo7YgL._SY522_.jpg'
                  alt=''
                  className='card-img-top rounded-3'
                />
                <div className='card-body px-2 pt-2 pb-0'>
                  <p className='h6 mb-0'>Eucharist</p>
                  <p className='text-muted mb-0'>Bishop Robert Barron</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='mb-3'>
          <p className='h5'>Apologetics</p>
        </div>
        <div className='mb-3'>
          <p className='h5'>Spiritual Classics</p>
        </div>
        <div className='mb-3'>
          <p className='h5'>Marriage and Family</p>
        </div>
      </div>

      <div className='my-5 py-3'></div>
      <BottomTabBar tab={NavigationTab.library} />
    </div>
  );
};

export default LibraryPage;
