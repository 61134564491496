import React, { useState } from 'react';
import docs, {
  DOCS_CLUSTER_CATHOLIC,
  DOCS_CLUSTER_CHURCH,
  DOCS_CLUSTER_GOD,
  DOCS_CLUSTER_HISTORY,
  DOCS_CLUSTER_PRACTICE,
  DOCS_TOPICS,
  DOCS_TOPICS_TYPE,
  DocsTopic,
} from '../../../../data/docs';
import { Link } from 'react-router-dom';
import './DocsSidebar.css';

const DocsTopicLevel = ({ topicName }: { topicName: DOCS_TOPICS_TYPE }) => {
  const [expanded, setExpanded] = useState(false);
  const toggleExpanded = () => setExpanded((st) => !st);

  return (
    <div
      className={
        docs[topicName].children.length > 0 && expanded ? 'mb-3' : 'mb-2'
      }
    >
      <div
        className='d-flex flex-row align-items-start'
        onClick={toggleExpanded}
        style={{ cursor: 'pointer' }}
      >
        <span className='material-symbols-rounded me-2'>
          {expanded ? 'expand_more' : 'chevron_right'}
        </span>

        <p
          className='h6 fw-bold text-dark DocsPage-section-title'
          style={{ marginTop: '0.15rem' }}
        >
          {docs[topicName].title}
        </p>
      </div>
      <div style={{ marginLeft: '2rem' }}>
        {expanded ? (
          docs[topicName].children.map((subtopic) => {
            return (
              <DocsSubtopicLevel
                topics={[docs[topicName], subtopic]}
                key={subtopic.slug}
              />
            );
          })
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

const DocsSubtopicLevel = ({ topics }: { topics: DocsTopic[] }) => {
  return (
    <div>
      <Link
        className='d-block text-decoration-none'
        to={`/docs/${topics.map((t) => t.slug).join('/')}`}
      >
        <p className='text-dark DocsPage-section-item'>
          {topics[topics.length - 1].title}
        </p>
      </Link>
    </div>
  );
};

export const DocsTitle = () => {
  return (
    <Link className='navbar-brand text-decoration-none' to={'/'}>
      <div className='d-flex flex-row align-items-center p-3'>
        <img
          src='/logo.png'
          alt='Logo'
          height='50'
          width='50'
          className='me-2'
        />
        <p className='mb-0 h5 fw-bold' style={{ marginRight: '0.3rem' }}>
          Catholicam
        </p>
        <p className='mb-0 h5 lead'>Docs</p>
      </div>
    </Link>
  );
};

const DocsSidebar = () => {
  return (
    <div>
      <div className='mb-3'>
        <h5 style={{ marginLeft: '2rem' }}>Introduction</h5>
      </div>
      <div className='mb-4'>
        <h5 style={{ marginLeft: '2rem' }}>God and the Soul</h5>
        {DOCS_CLUSTER_GOD.map((t) => (
          <DocsTopicLevel topicName={t} key={t} />
        ))}
      </div>
      <div className='mb-4'>
        <h5 style={{ marginLeft: '2rem' }}>The Catholic Church</h5>
        {DOCS_CLUSTER_CATHOLIC.map((t) => (
          <DocsTopicLevel topicName={t} key={t} />
        ))}
      </div>
      <div className='mb-4'>
        <h5 style={{ marginLeft: '2rem' }}>History</h5>
        {DOCS_CLUSTER_HISTORY.map((t) => (
          <DocsTopicLevel topicName={t} key={t} />
        ))}
      </div>
      <div className='mb-4'>
        <h5 style={{ marginLeft: '2rem' }}>The Church Today</h5>
        {DOCS_CLUSTER_CHURCH.map((t) => (
          <DocsTopicLevel topicName={t} key={t} />
        ))}
      </div>
      <div>
        <h5 style={{ marginLeft: '2rem' }}>Practicing the Faith</h5>
        {DOCS_CLUSTER_PRACTICE.map((t) => (
          <DocsTopicLevel topicName={t} key={t} />
        ))}
      </div>
    </div>
  );
};

export default DocsSidebar;
