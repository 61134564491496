import React, { useState } from 'react';
import BottomTabBar from '../../navigation/BottomTabBar';
import Navbar from '../../navigation/Navbar';
import { NavigationTab } from '../../navigation/NavigationTab';
import story, { STORY_PERIODS, StoryPeriod } from '../../../data/story';
import { Link, useParams } from 'react-router-dom';

const StorySubperiod = ({ subperiod }: { subperiod: StoryPeriod }) => {
  return (
    <Link to={'/story/'+subperiod.slug} className='text-decoration-none text-dark'>
      <div className='p-3 rounded my-2 bg-light'>
        <div>
          <p className='h5 mb-0'>{subperiod.title}</p>
          {subperiod.time ? (
            <p className='mb-0'>{subperiod.time}</p>
          ) : (
            <></>
          )}{' '}
        </div>
      </div>
    </Link>
  );
};

const StoryPeriodSection = (props: {
  period: StoryPeriod;
  renderLine: boolean;
  startingExpanded: boolean;
}) => {
  const [expanded, setExpanded] = useState(props.startingExpanded);

  const toggleExpanded = () => setExpanded((st) => !st);

  return (
    <div>
      <div
        className='d-flex align-items-center justify-content-between'
        onClick={toggleExpanded}
        style={{ cursor: 'pointer' }}
      >
        <div className='d-flex flex-row align-items-center'>
          <div className='border border-3 rounded-circle p-2'></div>
          <p className='h3 mb-0 ms-2'>{props.period.title}</p>
        </div>
      </div>
      <div
        className={props.renderLine ? 'border-start' : ''}
        style={{ marginLeft: '0.625rem' }}
      >
        <div
          className='ms-4'
          onClick={toggleExpanded}
          style={{ cursor: 'pointer' }}
        >
          {props.period.time ? (
            <p className='mb-0'>{props.period.time}</p>
          ) : (
            <span></span>
          )}
        </div>
        <div className='pt-2 pb-4'>
          <div
            className='ms-3'
            style={{ display: expanded ? 'block' : 'none' }}
          >
            {props.period.children.map((subperiod) => {
              return <StorySubperiod subperiod={subperiod} />;
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

const StoryPage = () => {  
  return (
    <div>
      <Navbar tab={NavigationTab.story} />
      <div className='container mb-3'>
        {STORY_PERIODS.map((periodName, index) => {
          const isLast = index === STORY_PERIODS.length - 1;
          return (
            <StoryPeriodSection
              period={story[periodName]}
              renderLine={!isLast}
              startingExpanded={false}
            />
          );
        })}
      </div>
      <div className='d-md-none pb-5 mb-3'></div>

      <BottomTabBar tab={NavigationTab.story} />
    </div>
  );
};

export default StoryPage;
