import { useParams } from 'react-router-dom';

export const DOCS_TOPICS: DOCS_TOPICS_TYPE[] = [
  'creator',
  'philosophy',
  'designer',
  'soul',
  'jesus',
  'church',
  'teaching',
  'miracles',
  'demonic',
  'history',
  'saints',
  'vision',
  'dioceses',
  'parishes',
  'bishops',
  'priests',
  'apostolates',
  'calendar',
  'prayers',
  'documents',
  'bible',
];

export const DOCS_CLUSTER_GOD: DOCS_TOPICS_TYPE[] = [
  'creator',
  'philosophy',
  'designer',
  'soul',
];

export const DOCS_CLUSTER_CATHOLIC: DOCS_TOPICS_TYPE[] = [
  'jesus',
  'church',
  'teaching',
];

export const DOCS_CLUSTER_HISTORY: DOCS_TOPICS_TYPE[] = [
  'miracles',
  'demonic',
  'history',
  'saints',
];

export const DOCS_CLUSTER_CHURCH: DOCS_TOPICS_TYPE[] = [
  'vision',
  'dioceses',
  'parishes',
  'bishops',
  'priests',
  'apostolates',
];

export const DOCS_CLUSTER_PRACTICE: DOCS_TOPICS_TYPE[] = [
  'calendar',
  'prayers',
  'documents',
  'bible',
];

export type DOCS_TOPICS_TYPE =
  | 'creator'
  | 'philosophy'
  | 'designer'
  | 'soul'
  | 'jesus'
  | 'church'
  | 'teaching'
  | 'miracles'
  | 'demonic'
  | 'history'
  | 'saints'
  | 'vision'
  | 'dioceses'
  | 'parishes'
  | 'bishops'
  | 'priests'
  | 'apostolates'
  | 'calendar'
  | 'prayers'
  | 'documents'
  | 'bible';

export interface DocsTopic {
  title: string;
  slug: string;
  children: DocsTopic[];
  content?: [];
}

export interface DocsMap {
  creator: DocsTopic;
  philosophy: DocsTopic;
  designer: DocsTopic;
  soul: DocsTopic;
  jesus: DocsTopic;
  church: DocsTopic;
  teaching: DocsTopic;
  miracles: DocsTopic;
  demonic: DocsTopic;
  history: DocsTopic;
  saints: DocsTopic;
  vision: DocsTopic;
  dioceses: DocsTopic;
  parishes: DocsTopic;
  bishops: DocsTopic;
  priests: DocsTopic;
  apostolates: DocsTopic;
  calendar: DocsTopic;
  prayers: DocsTopic;
  documents: DocsTopic;
  bible: DocsTopic;
}

export const getDoc = (address: string[]): DocsTopic => {
  let hierarchy: DocsTopic[] = [docs[address[0] as DOCS_TOPICS_TYPE]];

  address.slice(1).forEach((slug) => {
    const lastDoc = hierarchy[hierarchy.length - 1];
    hierarchy.push(lastDoc.children.filter((d) => d.slug === slug)[0]);
  });

  return hierarchy[hierarchy.length - 1];
};

export const docs: DocsMap = {
  creator: {
    title: 'Scientific Evidence of a Creator',
    slug: 'creator',
    children: [
      {
        title: 'The Big Bang Theory',
        slug: 'the-big-bang',
        children: [],
      },
      {
        title: 'Can the existence of God be disproved?',
        slug: 'disprove',
        children: [],
      },
      {
        title: 'Why are there atheist scientists?',
        slug: 'atheist-scientists',
        children: [],
      },
    ],
  },
  philosophy: {
    title: "Philosophical Proofs for God's Existence",
    slug: 'philosophy',
    children: [
      {
        title: "Thomas Aquinas's Five Ways",
        slug: 'aquinas',
        children: [],
      },
      {
        title: 'A Contemporary Proof',
        slug: 'contemporary',
        children: [],
      },
      {
        title: 'A Lonerganian Proof',
        slug: 'lonerganian',
        children: [],
      },
    ],
  },
  designer: {
    title: 'Evidence of an Intelligent Designer',
    slug: 'designer',
    children: [],
  },
  soul: {
    title: 'Evidence of the Transphysical Human Soul',
    slug: 'soul',
    children: [
      {
        title: 'Near Death Experiences',
        slug: 'nde',
        children: [],
      },
      {
        title: 'The Transcendental Desires',
        slug: 'transcendentals',
        children: [],
      },
      {
        title: 'The Human Conscience',
        slug: 'conscience',
        children: [],
      },
      {
        title: 'Human Intelligence',
        slug: 'intelligence',
        children: [],
      },
      {
        title: 'Self-Consciousness',
        slug: 'consciousness',
        children: [],
      },
      {
        title: 'Free Will',
        slug: 'free-will',
        children: [],
      },
    ],
  },
  jesus: {
    title: 'Historicity & Divinity of Jesus',
    slug: 'jesus',
    children: [],
  },
  church: {
    title: 'The Catholic Church',
    slug: 'church',
    children: [
      {
        title: 'Founding of the Catholic Church',
        slug: 'founding',
        children: [],
      },
      {
        title: 'Why do we need a Church?',
        slug: 'why',
        children: [],
      },
      {
        title: 'Why the Catholic Church?',
        slug: 'catholic',
        children: [],
      },
      {
        title: 'Purpose and Benefit of the Church',
        slug: 'purpose',
        children: [],
      },
      {
        title: 'Structure and Authority',
        slug: 'structure',
        children: [
          {
            title: 'The Magisterium',
            slug: 'magisterium',
            children: [],
          },
          {
            title: 'Structure of Dioceses',
            slug: 'dioceses',
            children: [],
          },
          {
            title: 'Structure of Parishes',
            slug: 'parishes',
            children: [],
          },
        ],
      },
    ],
  },
  teaching: {
    title: 'Church Teaching',
    slug: 'teaching',
    children: [
      {
        title: 'The Nature of Jesus',
        slug: 'jesus',
        children: [],
      },
      {
        title: 'Life after Death',
        slug: 'life',
        children: [],
      },
      {
        title: 'The Seven Sacraments',
        slug: 'sacraments',
        children: [],
      },
      {
        title: 'The Mass',
        slug: 'mass',
        children: [],
      },
      {
        title: 'How to go to Heaven',
        slug: 'salvation',
        children: [],
      },
      {
        title: 'Salvation outside the Catholic Church',
        slug: 'outside-church',
        children: [],
      },
      {
        title: 'The Evangelical Mandate',
        slug: 'evangelization',
        children: [],
      },
      {
        title: 'Why does God require our involvement in human salvation?',
        slug: 'involvement-in-salvation',
        children: [],
      },
      {
        title: 'Non-Catholic Religions',
        slug: 'non-catholics',
        children: [
          {
            title: 'Eastern Orthodoxy',
            slug: 'eastern-orthodoxy',
            children: [],
          },
          {
            title: 'Protestantism',
            slug: 'protestantism',
            children: [],
          },
          {
            title: 'Judaism',
            slug: 'judaism',
            children: [],
          },
          {
            title: 'Islam',
            slug: 'islam',
            children: [],
          },
          {
            title: 'Hinduism',
            slug: 'hinduism',
            children: [],
          },
          {
            title: 'Sikhism',
            slug: 'sikhism',
            children: [],
          },
        ],
      },
    ],
  },
  miracles: {
    title: 'Miracles',
    slug: 'miracles',
    children: [
      {
        title: 'Apparitions',
        slug: 'apparitions',
        children: [],
      },
      {
        title: 'Miraculous Images',
        slug: 'images',
        children: [],
      },
      {
        title: 'Eucharistic Miracles',
        slug: 'eucharistic',
        children: [],
      },
      {
        title: 'Stigmata',
        slug: 'stigmata',
        children: [],
      },
      {
        title: 'Incorruptibles',
        slug: 'incorruptibles',
        children: [],
      },
      {
        title: 'Canonization Miracles',
        slug: 'canonization',
        children: [],
      },
      {
        title: 'Miracles from Lourdes, France',
        slug: 'lourdes',
        children: [],
      },
      {
        title: 'Miracles of the Lives of the Saints',
        slug: 'saints',
        children: [],
      },
      {
        title: 'Miracle of the Sun (1917)',
        slug: 'miracle-of-the-sun-1917',
        children: [],
      },
      {
        title: 'Miracle of the Sun (2017)',
        slug: 'miracle-of-the-sun-2017',
        children: [],
      },
    ],
  },
  demonic: {
    title: 'Demonic Activity',
    slug: 'demonic',
    children: [
      {
        title: 'Known Exorcists',
        slug: 'exorcists',
        children: [],
      },
      {
        title: 'Recorded Exorcisms',
        slug: 'exorcisms',
        children: [],
      },
    ],
  },
  history: {
    title: 'Church & World History',
    slug: 'history',
    children: [
      {
        title: 'Popes',
        slug: 'popes',
        children: [],
      },
      {
        title: 'Church Councils',
        slug: 'councils',
        children: [],
      },
      {
        title: 'Civilizations',
        slug: 'wars',
        children: [],
      },
      {
        title: 'Wars',
        slug: 'wars',
        children: [],
      },
    ],
  },
  saints: {
    title: 'Saints',
    slug: 'saints',
    children: [],
  },
  vision: {
    title: 'World-Transforming Vision',
    slug: 'vision',
    children: [
      {
        title: 'Protecting Human Life',
        slug: 'protect-life',
        children: [],
      },
      {
        title: 'Uplifting the poor',
        slug: 'uplift-poor',
        children: [],
      },
      {
        title: 'Ending Human Trafficking',
        slug: 'end-trafficking',
        children: [],
      },
      {
        title: 'Caring for Refugees and Immigrants',
        slug: 'refugees-immigrants',
        children: [],
      },
      {
        title: 'Ensuring Religious Freedom',
        slug: 'religious-freedom',
        children: [],
      },
      {
        title: 'Ending Individual and Systemic Racism',
        slug: 'end-racism',
        children: [],
      },
      {
        title: 'Promoting World Peace',
        slug: 'world-peace',
        children: [],
      },
      {
        title: 'Promoting Authentic Human Love',
        slug: 'authentic-love',
        children: [],
      },
    ],
  },
  dioceses: {
    title: 'Dioceses',
    slug: 'dioceses',
    children: [
      {
        title: 'North America',
        slug: 'north-america',
        children: [],
      },
      {
        title: 'Central America',
        slug: 'central-america',
        children: [],
      },
      {
        title: 'South America',
        slug: 'south-america',
        children: [],
      },
      {
        title: 'Oceania',
        slug: 'oceania',
        children: [],
      },
      {
        title: 'Africa',
        slug: 'africa',
        children: [],
      },
      {
        title: 'Asia',
        slug: 'asia',
        children: [],
      },
      {
        title: 'Europe',
        slug: 'europe',
        children: [],
      },
    ],
  },
  parishes: {
    title: 'Parishes',
    slug: 'parishes',
    children: [],
  },
  bishops: {
    title: 'Bishops',
    slug: 'bishops',
    children: [],
  },
  priests: {
    title: 'Priests',
    slug: 'priests',
    children: [],
  },
  apostolates: {
    title: 'Apostolates',
    slug: 'apostolates',
    children: [],
  },
  calendar: {
    title: 'Liturgical Calendar',
    slug: 'calendar',
    children: [],
  },
  prayers: {
    title: 'Prayers',
    slug: 'prayers',
    children: [],
  },
  documents: {
    title: 'Documents',
    slug: 'documents',
    children: [
      {
        title: 'Church Fathers',
        slug: 'church-fathers',
        children: [],
      },
    ],
  },
  bible: {
    title: 'The Bible',
    slug: 'bible',
    children: [],
  },
};

export default docs;
