import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import './BottomTabBar.css';
import { NavigationTab } from './NavigationTab';
import DocsSidebar from '../pages/Docs/DocsSidebar/DocsSidebar';

interface BottomTabBarProps {
  tab: NavigationTab;
}

const BottomTabBar = (props: BottomTabBarProps) => {
  const { pathname } = useLocation();

  const isDocs = pathname.replace('/', '').startsWith('docs');

  const [docsDrawerExpanded, setDocsDrawerExpanded] = useState(false);
  const toggleDocsDrawerExpanded = () => setDocsDrawerExpanded((st) => !st);

  const docsAndExpanded = isDocs && docsDrawerExpanded;

  return (
    <div
      className={`fixed-bottom ${
        docsAndExpanded ? 'd-flex flex-column justify-content-between' : ''
      } `}
      style={isDocs && docsDrawerExpanded ? { height: '100%' } : {}}
    >
      {isDocs && (
        <div
          className={`d-md-none d-flex flex-row align-items-center justify-content-between p-3 bg-white ${
            docsDrawerExpanded ? 'border-bottom' : 'border-top'
          }`}
          onClick={toggleDocsDrawerExpanded}
        >
          <div className='d-flex flex-column'>
            <p className='mb-0 h5'>God and the Soul</p>
            <p className='mb-0'>The Big Bang</p>
          </div>
          <span className='material-symbols-rounded'>unfold_more</span>
        </div>
      )}
      {isDocs && (
        <div
          className={`d-md-none px-2 py-3 bg-white ${
            docsDrawerExpanded ? 'd-block' : 'd-none'
          }`}
          style={{ overflow: 'scroll' }}
        >
          <DocsSidebar />
        </div>
      )}

      <div className='d-flex flex-row justify-content-evenly d-md-none  border-top px-2 pb-2 bg-white'>
        <Link to={'/home'} className='text-decoration-none'>
          <div
            className={`d-flex flex-row align-items-center px-2 BottomTabBar-py fw-bold ${
              props.tab === NavigationTab.home
                ? 'text-dark border-top border-dark border-3'
                : 'text-muted border-top border-white border-3'
            }`}
          >
            <span className='material-symbols-rounded'>home</span>
          </div>
        </Link>

        <Link to={'/learn'} className='text-decoration-none'>
          <div
            className={`d-flex flex-row align-items-center px-2 BottomTabBar-py fw-bold ${
              props.tab === NavigationTab.learn
                ? 'text-dark border-top border-dark border-3'
                : 'text-muted border-top border-white border-3'
            }`}
          >
            <span className='material-symbols-rounded'>donut_large</span>
          </div>
        </Link>

        <Link to={'/story'} className='text-decoration-none'>
          <div
            className={`d-flex flex-row align-items-center px-2 BottomTabBar-py fw-bold ${
              props.tab === NavigationTab.story
                ? 'text-dark border-top border-dark border-3'
                : 'text-muted border-top border-white border-3'
            }`}
          >
            <span className='material-symbols-rounded'>timeline</span>
          </div>
        </Link>

        <Link to={'/docs'} className='text-decoration-none'>
          <div
            className={`d-flex flex-row align-items-center px-2 BottomTabBar-py fw-bold ${
              props.tab === NavigationTab.docs
                ? 'text-dark border-top border-dark border-3'
                : 'text-muted border-top border-white border-3'
            }`}
          >
            <span className='material-symbols-rounded'>library_books</span>
          </div>
        </Link>

        <Link to={'/maps'} className='text-decoration-none'>
          <div
            className={`d-flex flex-row align-items-center px-2 BottomTabBar-py fw-bold ${
              props.tab === NavigationTab.maps
                ? 'text-dark border-top border-dark border-3'
                : 'text-muted border-top border-white border-3'
            }`}
          >
            <span className='material-symbols-rounded'>map</span>
          </div>
        </Link>
        <Link to={'/library'} className='text-decoration-none'>
          <div
            className={`d-flex flex-row align-items-center px-2 BottomTabBar-py fw-bold ${
              props.tab === NavigationTab.library
                ? 'text-dark border-top border-dark border-3'
                : 'text-muted border-top border-white border-3'
            }`}
          >
            <span className='material-symbols-rounded'>
              collections_bookmark
            </span>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default BottomTabBar;
