import React from 'react';
import BottomTabBar from '../../navigation/BottomTabBar';
import Navbar from '../../navigation/Navbar';
import { NavigationTab } from '../../navigation/NavigationTab';

const MapsPage = () => {
  return (
    <div>
      <Navbar tab={NavigationTab.maps}/>
      <div className='container'>
        <h1>Maps</h1>
      </div>
      <BottomTabBar tab={NavigationTab.maps} />
    </div>
  );
};

export default MapsPage;
