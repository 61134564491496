import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Navbar from '../../../navigation/Navbar';
import BottomTabBar from '../../../navigation/BottomTabBar';

import docs, { DOCS_TOPICS_TYPE, DocsTopic } from '../../../../data/docs';
import { NavigationTab } from '../../../navigation/NavigationTab';
import DocsSidebar, { DocsTitle } from '../DocsSidebar/DocsSidebar';
import ScrollToTop from '../../../navigation/ScrollToTop';
import EucharisticMiracles from '../Content/Miracles/Eucharistic/EucharisticMiracles';
import Introduction from '../Content/Introduction/Introduction';

import './DocPage.css';
import reportWebVitals from '../../../../reportWebVitals';

const PageContent = () => {
  return (
    <div>
      <p>
        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Similique
        sapiente assumenda corrupti excepturi, debitis ipsa perferendis nesciunt
        tenetunisi ipsum?
      </p>
      <p>
        Animi recusandae esse repellat dolorem totam, neque aliquid at, ducimus,
        nisi sed voluptas officiis similique quidem saepe nam velit minus.
      </p>
      <p>
        Animi eveniet veritatis harum unde explicabo iste, officia sunt ducimus
        quos rerum dolorem incidunt itaque voluptatum commodi id neque quo!
      </p>
      <p>
        Porro consequuntur sit praesentium, suscipit nesciunt harum quibusdam
        molestias hic similique sed dignissimos doloribus architecto quas esse
        totam optio facere.
      </p>
      <p>
        Quia cum in ipsum esse adipisci magnam, beatae ut eligendi, quaerat quo
        placeat, dignissimos error a aut molestias at quibusdam.
      </p>
      <p>
        Nisi delectus sint aspernatur! Perferendis, saepe vero aut consequatur
        neque similique magni aliquam beatae at amet. Eius facere suscipit
        sequi!
      </p>
      <p>
        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Similique
        sapiente assumenda corrupti excepturi, debitis ipsa perferendis nesciunt
        tenetunisi ipsum?
      </p>
      <p>
        Animi recusandae esse repellat dolorem totam, neque aliquid at, ducimus,
        nisi sed voluptas officiis similique quidem saepe nam velit minus.
      </p>
      <p>
        Animi eveniet veritatis harum unde explicabo iste, officia sunt ducimus
        quos rerum dolorem incidunt itaque voluptatum commodi id neque quo!
      </p>
      <p>
        Porro consequuntur sit praesentium, suscipit nesciunt harum quibusdam
        molestias hic similique sed dignissimos doloribus architecto quas esse
        totam optio facere.
      </p>
      <p>
        Quia cum in ipsum esse adipisci magnam, beatae ut eligendi, quaerat quo
        placeat, dignissimos error a aut molestias at quibusdam.
      </p>
      <p>
        Nisi delectus sint aspernatur! Perferendis, saepe vero aut consequatur
        neque similique magni aliquam beatae at amet. Eius facere suscipit
        sequi!
      </p>
      <p>
        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Similique
        sapiente assumenda corrupti excepturi, debitis ipsa perferendis nesciunt
        tenetunisi ipsum?
      </p>
      <p>
        Animi recusandae esse repellat dolorem totam, neque aliquid at, ducimus,
        nisi sed voluptas officiis similique quidem saepe nam velit minus.
      </p>
      <p>
        Animi eveniet veritatis harum unde explicabo iste, officia sunt ducimus
        quos rerum dolorem incidunt itaque voluptatum commodi id neque quo!
      </p>
      <p>
        Porro consequuntur sit praesentium, suscipit nesciunt harum quibusdam
        molestias hic similique sed dignissimos doloribus architecto quas esse
        totam optio facere.
      </p>
      <p>
        Quia cum in ipsum esse adipisci magnam, beatae ut eligendi, quaerat quo
        placeat, dignissimos error a aut molestias at quibusdam.
      </p>
      <p>
        Nisi delectus sint aspernatur! Perferendis, saepe vero aut consequatur
        neque similique magni aliquam beatae at amet. Eius facere suscipit
        sequi!
      </p>
      <p>
        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Similique
        sapiente assumenda corrupti excepturi, debitis ipsa perferendis nesciunt
        tenetunisi ipsum?
      </p>
      <p>
        Animi recusandae esse repellat dolorem totam, neque aliquid at, ducimus,
        nisi sed voluptas officiis similique quidem saepe nam velit minus.
      </p>
      <p>
        Animi eveniet veritatis harum unde explicabo iste, officia sunt ducimus
        quos rerum dolorem incidunt itaque voluptatum commodi id neque quo!
      </p>
      <p>
        Porro consequuntur sit praesentium, suscipit nesciunt harum quibusdam
        molestias hic similique sed dignissimos doloribus architecto quas esse
        totam optio facere.
      </p>
      <p>
        Quia cum in ipsum esse adipisci magnam, beatae ut eligendi, quaerat quo
        placeat, dignissimos error a aut molestias at quibusdam.
      </p>
      <p>
        Nisi delectus sint aspernatur! Perferendis, saepe vero aut consequatur
        neque similique magni aliquam beatae at amet. Eius facere suscipit
        sequi!
      </p>
      <p>
        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Similique
        sapiente assumenda corrupti excepturi, debitis ipsa perferendis nesciunt
        tenetunisi ipsum?
      </p>
      <p>
        Animi recusandae esse repellat dolorem totam, neque aliquid at, ducimus,
        nisi sed voluptas officiis similique quidem saepe nam velit minus.
      </p>
      <p>
        Animi eveniet veritatis harum unde explicabo iste, officia sunt ducimus
        quos rerum dolorem incidunt itaque voluptatum commodi id neque quo!
      </p>
      <p>
        Porro consequuntur sit praesentium, suscipit nesciunt harum quibusdam
        molestias hic similique sed dignissimos doloribus architecto quas esse
        totam optio facere.
      </p>
      <p>
        Quia cum in ipsum esse adipisci magnam, beatae ut eligendi, quaerat quo
        placeat, dignissimos error a aut molestias at quibusdam.
      </p>
      <p>
        Nisi delectus sint aspernatur! Perferendis, saepe vero aut consequatur
        neque similique magni aliquam beatae at amet. Eius facere suscipit
        sequi!
      </p>
    </div>
  );
};

const ContentSidebar = () => {
  return (
    <div className='sticky-md-top pt-3'>
      <p className='h5 DocPage-content-sidebar-item'>Contents</p>
      <p className='DocPage-content-sidebar-item'>Origins of the theory</p>
      <p className='DocPage-content-sidebar-item'>Evidence</p>
      <div className='ms-3'>
        <p className='DocPage-content-sidebar-item'>BGV Proof</p>
        <p className='DocPage-content-sidebar-item'>Redshifting of galaxies</p>
        <p className='DocPage-content-sidebar-item'>
          Cosmic Microwave Background Radiation
        </p>
      </div>
      <p className='DocPage-content-sidebar-item'>Significance</p>
      <p className='DocPage-content-sidebar-item'>Multiverse theory</p>
    </div>
  );
};

const MobileContentSidebar = () => {
  const [expanded, setExpanded] = useState(false);
  const toggleExpanded = () => setExpanded((st) => !st);

  return (
    <div className='card card-body shadow-sm'>
      <div
        className='d-flex flex-row justify-content-between'
        onClick={toggleExpanded}
      >
        <p className='h5 mb-0'>Contents</p>
        <span className='material-symbols-rounded'>unfold_more</span>
      </div>

      <div className={`mt-2 ${expanded ? '' : 'd-none'}`}>
        <p className='DocPage-content-sidebar-item'>Origins of the theory</p>
        <p className='DocPage-content-sidebar-item'>Evidence</p>
        <div className='ms-3'>
          <p className='DocPage-content-sidebar-item'>BGV Proof</p>
          <p className='DocPage-content-sidebar-item'>
            Redshifting of galaxies
          </p>
          <p className='DocPage-content-sidebar-item'>
            Cosmic Microwave Background Radiation
          </p>
        </div>
        <p className='DocPage-content-sidebar-item'>Significance</p>
        <p className='DocPage-content-sidebar-item'>Multiverse theory</p>
      </div>
    </div>
  );
};

const getPageContent = (pathname: string) => {
  if (pathname === '/docs/') {
    return <Introduction />;
  } else if (pathname === '/docs/miracles/eucharistic') {
    return <EucharisticMiracles />;
  } else {
    return <PageContent />;
  }
};

const vp = (
  viewportWidth: number,
  wideStyles: Object,
  slimStyles: Object
): Object => {
  if (viewportWidth >= 786) {
    return wideStyles;
  } else {
    return slimStyles;
  }
};

const DocPage = () => {
  const { pathname } = useLocation();

  const breadcrumbs = pathname.split('/').slice(2);
  let docsHierarchy: DocsTopic[] = [docs[breadcrumbs[0] as DOCS_TOPICS_TYPE]];

  breadcrumbs.slice(1).forEach((c) => {
    const lastDoc = docsHierarchy[docsHierarchy.length - 1];
    docsHierarchy.push(lastDoc.children.filter((d) => d.slug === c)[0]);
  });

  let title =
    pathname.replace('/', '') === 'docs'
      ? 'Introduction'
      : docsHierarchy[docsHierarchy.length - 1].title;

  return (
    <div>
      <ScrollToTop />
      <div
        // style={vp(
        //   window.innerWidth,
        //   { height: '100vh', overflow: 'scroll' },
        //   {}
        // )}
        className='d-flex flex-column'
      >
        <Navbar tab={NavigationTab.docs} borderBottom />
        <div
          className='container-fluid flex-fill'
          // style={vp(window.innerWidth, { overflow: 'scroll' }, {})}
        >
          <div className='row' style={{ height: '100%' }}>
            <div
              className='col-12 col-md-4 col-xl-3 d-none d-md-block'
              // style={vp(
              //   window.innerWidth,
              //   { height: '100%', overflow: 'scroll' },
              //   {}
              // )}
            >
              <div className='mt-3'></div>
              <DocsSidebar />
            </div>
            <div
              className='col-12 col-md-8 col-xl-9'
              // style={vp(
              //   window.innerWidth,
              //   { height: '100%', overflow: 'scroll' },
              //   {}
              // )}
            >
              <div className='row'>
                <div className='col-12 col-md-9'>
                  <div className='d-none d-md-block mt-3'></div>
                  <div className='mt-3'>
                    <h2>{title}</h2>
                    <h4 className='lead'>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Dolores, laudantium.
                    </h4>
                  </div>

                  <div>
                    <div className='d-flex flex-row py-2'>
                      <span className='material-symbols-rounded pe-2'>
                        person
                      </span>
                      <div className='d-flex flex-column'>
                        <p className='h6 flex-grow-1 mb-0'>
                          Edited by{' '}
                          <span className='fw-bold'>Dr. John Smith</span>
                        </p>
                        <p className='mb-2'>
                          Professor of Biology at University of Notre Dame
                        </p>
                        <div className='d-flex flex-row'>
                          <p className='mb-0'>
                            Author of{' '}
                            <span className='fw-bold'>
                              God's Grandeur: The Catholic Case for Intelligent
                              Design
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='d-md-none mb-3 mt-2'>
                    <MobileContentSidebar />
                  </div>

                  {getPageContent(pathname)}
                </div>
                <div className='col-12 col-md-3 d-none d-md-block'>
                  <ContentSidebar />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='d-md-none my-5 py-4'></div>
      <BottomTabBar tab={NavigationTab.docs} />
    </div>
  );
};

export default DocPage;

// {/* <nav aria-label='breadcrumb'>
//                 <ol className='breadcrumb'>
//                   <li className='breadcrumb-item'>
//                     <Link to={'/docs'}>Docs</Link>
//                   </li>
//                   {inactiveBreadcrumbs.map((crumb, i) => {
//                     return (
//                       <li className='breadcrumb-item'>
//                         <Link
//                           to={`/docs/${inactiveBreadcrumbs
//                             .slice(0, i + 1)
//                             .join('/')}`}
//                         >
//                           {docsHierarchy[i].title}
//                         </Link>
//                       </li>
//                     );
//                   })}
//                   <li className='breadcrumb-item active' aria-current='page'>
//                     {docsHierarchy[docsHierarchy.length - 1].title}
//                   </li>
//                 </ol>
//               </nav> */}
