import React from 'react';

const EucharisticMiracles = () => {
  return (
    <div>
      <div className='card mb-2'>
        <div className='card-body'>
          <h4 className='card-title'>Legnica, Poland, 2013</h4>
          <p className='mb-0'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Dicta doloremque quia eaque autem molestiae et voluptatibus magni, illum unde harum.</p>
        </div>
      </div>
      <div className='card mb-2'>
        <div className='card-body'>
          <h4 className='card-title'>Chirattakonam, India, 2001</h4>
          <p className='mb-0'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Dicta doloremque quia eaque autem molestiae et voluptatibus magni, illum unde harum.</p>
        </div>
      </div>
      <div className='card mb-2'>
        <div className='card-body'>
          <h4 className='card-title'>Tixtla, Mexico, 2006</h4>
          <p className='mb-0'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Dicta doloremque quia eaque autem molestiae et voluptatibus magni, illum unde harum.</p>
        </div>
      </div>
      <div className='card mb-2'>
        <div className='card-body'>
          <h4 className='card-title'>Sokolka, Poland, 2008</h4>
          <p className='mb-0'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Dicta doloremque quia eaque autem molestiae et voluptatibus magni, illum unde harum.</p>
        </div>
      </div>
    </div>
  );
};

export default EucharisticMiracles;
