export enum NavigationTab {
  home,
  story,
  docs,
  maps,
  learn,
  search,
  library,
  account,
  none,
}
