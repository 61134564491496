import React, { useEffect } from 'react';
import {
  createBrowserRouter,
  redirect,
  RouterProvider,
  useLocation,
} from 'react-router-dom';

import LandingPage from './pages/LandingPage';
import HomePage from './pages/Home/HomePage';
import StoryPage from './pages/Story/StoryPage';
import DocsPage from './pages/Docs/DocsPage/DocsPage';
import MapsPage from './pages/Maps/MapsPage';

import DocPage from './pages/Docs/DocPage/DocPage';
import StorySubperiodPage from './pages/Story/StorySubperiodPage';

import SearchPage from './pages/Search/SearchPage';
import LibraryPage from './pages/Library/LibraryPage';
import AccountPage from './pages/Account/AccountPage';
import LearnPage from './pages/Learn/LearnPage';

const router = createBrowserRouter([
  {
    path: '/',
    element: <LandingPage />,
  },
  {
    path: '/home',
    element: <HomePage />,
  },
  {
    path: '/learn',
    element: <LearnPage />,
  },
  {
    path: '/story',
    element: <StoryPage />,
  },
  {
    path: '/story/*',
    element: <StorySubperiodPage />,
  },
  {
    path: '/docs',
    element: <DocPage />,
  },
  {
    path: '/docs/*',
    element: <DocPage />,
  },
  {
    path: '/maps',
    element: <MapsPage />,
  },
  {
    path: '/search',
    element: <SearchPage />,
  },
  {
    path: '/library',
    element: <LibraryPage />,
  },
  {
    path: '/account',
    element: <AccountPage />,
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
