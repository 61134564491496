import React, { useState } from 'react';
import BottomTabBar from '../../navigation/BottomTabBar';
import Navbar from '../../navigation/Navbar';
import { NavigationTab } from '../../navigation/NavigationTab';
import story, {
  STORY_PERIODS,
  STORY_PERIODS_TYPE,
  StoryPeriod,
} from '../../../data/story';
import { Link, useLocation } from 'react-router-dom';

const StorySubperiodPage = () => {
  const { pathname } = useLocation();
  const parts = pathname.split('/');
  const period = story[parts[2] as STORY_PERIODS_TYPE];

  const periodPath = parts.slice(2).join('/');
  const subperiod = period.children.filter((sp) => sp.slug === periodPath)[0];

  return (
    <div>
      <Navbar tab={NavigationTab.story} />
      <div className='container'>
        <Link
          to={'/story/'}
          className='text-decoration-none text-dark'
        >
          <p className='h5 d-flex flex-row align-items-center mb-3'>
            <span className='material-symbols-rounded me-2'>arrow_back</span>
            <span>Story</span>
            <span className='mx-2'>•</span>
            <span>{period.title}</span>
          </p>
        </Link>

        <h2>{subperiod.title}</h2>
      </div>
      <div className='d-md-none pb-5 mb-3'></div>

      <BottomTabBar tab={NavigationTab.story} />
    </div>
  );
};

export default StorySubperiodPage;
