import React, { useState } from 'react';
import Navbar from '../navigation/Navbar';
import BottomTabBar from '../navigation/BottomTabBar';
import { Link } from 'react-router-dom';
import { NavigationTab } from '../navigation/NavigationTab';

const LandingQuestion = (props: React.PropsWithChildren<{ title: string }>) => {
  const [expanded, setExpanded] = useState(false);
  const toggleExpanded = () => setExpanded((st) => !st);

  return (
    <div>
      <div
        className='d-flex flex-row align-items-start'
        onClick={toggleExpanded}
        style={{ cursor: 'pointer' }}
      >
        <div className='me-2' style={{ marginTop: '0.15rem' }}>
          <span
            className='material-symbols-rounded'
            style={{ fontSize: '2rem' }}
          >
            {expanded ? 'expand_more' : 'chevron_right'}
          </span>
        </div>

        <h3>{props.title}</h3>
      </div>
      {expanded && <div style={{ marginLeft: '2.5rem' }}>{props.children}</div>}
    </div>
  );
};

const LandingPage = () => {
  return (
    <div>
      <Navbar tab={NavigationTab.none} />
      <BottomTabBar tab={NavigationTab.none} />

      <div className='container mt-3 text-center'>
        <h1 className='text-center' style={{ fontSize: '6rem' }}>
          The <span className='fw-bold'>Catholic</span> Faith
        </h1>
        <h2>is the fullness of the Truth revealed by God to Humanity</h2>
      </div>
      <div className='container mt-5'>
        <div className='d-flex flex-column align-items-center'>
          <Link to={'/story'}>
            <button className='btn btn-primary px-4 py-3 rounded-pill shadow-sm'>
              <span className='h6'>Explore the Story of Reality</span>
            </button>
          </Link>
        </div>
      </div>
      <div className='mt-5 py-5 px-3 bg-light text-dark'>
        <div className='container mb-4'>
          <h2 className='mb-0'>
            Instituted by Christ himself, the Catholic Church offers the entire
            world the fullness of the means of salvation
          </h2>
        </div>
        <div className='container'>
          <div className='row'>
            <div className='col-12 col-md-6 mb-3'>
              <LandingQuestion
                title={
                  'How can I trust that Jesus really existed and rose from the dead?'
                }
              >
                <p className='h6'>
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                  Autem, similique. Illum aperiam dolores aliquid facere
                  cupiditate pariatur quidem autem est!
                </p>
              </LandingQuestion>
            </div>
            <div className='col-12 col-md-6 mb-3'>
              <LandingQuestion
                title={
                  'How can I know that Jesus really founded the Catholic Church to carry out his saving mission for the entire world?'
                }
              >
                <p className='h6'>
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                  Autem, similique. Illum aperiam dolores aliquid facere
                  cupiditate pariatur quidem autem est!
                </p>
              </LandingQuestion>
            </div>
          </div>
          <div className='row'>
            <div className='col-12 col-md-6 mb-3'>
              <LandingQuestion
                title={
                  'How can I trust that the Catholic Church has the same authority today as when it was founded by Jesus?'
                }
              >
                <p className='h6'>
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                  Autem, similique. Illum aperiam dolores aliquid facere
                  cupiditate pariatur quidem autem est!
                </p>
              </LandingQuestion>
            </div>
            <div className='col-12 col-md-6 mb-3'>
              <LandingQuestion
                title={
                  'What happens when I die, and how can I know that I will go to heaven?'
                }
              >
                <p className='h6'>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquid itaque repudiandae vitae dolores ducimus recusandae sapiente perferendis illum odio soluta.
                </p>
              </LandingQuestion>
            </div>
          </div>
        </div>
      </div>
      <div className='my-5'>
        <div className='container'>
          <div className='row'>
            <div className='col-12 col-md-4 col-xl-3'>
              <div className='card border-0 mb-4'>
                <img
                  src='https://snworksceo.imgix.net/cav/c46778b3-cb6d-4904-bf6e-6100130a618c.sized-1000x1000.jpg?w=1000'
                  alt=''
                  className='card-img-top rounded'
                />
                <h4 className='card-title mt-2'>Near Death Experiences</h4>
              </div>
            </div>
            <div className='col-12 col-md-4 col-xl-3'>
              <div className='card border-0 mb-4'>
                <img
                  src='https://miro.medium.com/v2/resize:fit:1095/1*Db4pYKSgC4rEFFutRB6ZtQ.jpeg'
                  alt=''
                  className='card-img-top rounded'
                />
                <h4 className='card-title mt-2'>Eucharistic Miracles</h4>
              </div>
            </div>
            <div className='col-12 col-md-4 col-xl-3'>
              <div className='card border-0 mb-4'>
                <img
                  src='https://i.ytimg.com/vi/Mi6kbETV8wI/maxresdefault.jpg'
                  alt=''
                  className='card-img-top rounded'
                />
                <h4 className='card-title mt-2'>Marian Apparitions</h4>
              </div>
            </div>
            <div className='col-12 col-md-4 col-xl-3'>
              <div className='card border-0 mb-4'>
                <img
                  src='https://miro.medium.com/v2/resize:fit:1024/1*l908ZHBnBIdIifuV5HMHZg.jpeg'
                  alt=''
                  className='card-img-top rounded'
                />
                <h4 className='card-title mt-2'>The Shroud of Turin</h4>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='my-5'>
        <div className='container'>
          <h2 className='fw-bold text-center'>
            Curious? Find an Alpha near you
          </h2>
          <div className='d-flex flex-column align-items-center mt-3 px-3'>
            <p className='h5'>
              Alpha is 10-week course on the basics of the Catholic Faith.
            </p>
            <p className='h5 text-muted'>
              Share a meal, hear a talk, and ask questions. No judgement.
            </p>
          </div>
        </div>
      </div>
      <div className='d-md-none pb-5 mb-3'></div>
    </div>
  );
};

export default LandingPage;
